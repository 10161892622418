/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

const MenuChildren = ({ data, handleClick, generateLink, mainData }) => {
    const [active, setActive] = React.useState(0);
    const getCount = (count) => {
        if (!count || count <= 4) return 1;
        if (count > 4 && count <= 8) return 2;
        if (count > 8 && count <= 12) return 3;
        return 4;
    };

    return (
        <div className="main-content">
            {mainData && mainData.show_left_sidebar && (
                <div className="left-sidebar" style={{ width: mainData.left_sidebar_width }}>
                    <CmsRenderer content={mainData.left_sidebar_html} />
                </div>
            )}
            <div
                className={cx('nav-column swift-nav-column-left', 'min-w-[150px]', {
                    '!bg-neutral-white': mainData && mainData.dropdown_bgcolor === '',
                })}
            >
                {data.map((val, idx) => (
                    <Link key={idx} href={generateLink(val)[0]} as={generateLink(val)[1]} prefetch={false} legacyBehavior>
                        <a
                            onClick={() => handleClick(val)}
                            className={cx(
                                active === idx ? 'active' : '',
                                '!text-neutral-700',
                                '!text-md',
                                '!font-medium',
                                '!leading-7',
                                '!px-2',
                                '!py-[12px]',
                                'swift-nav-menu-lv2',
                                '!max-w-max',
                                'max-h-2',
                                'inline-block',
                            )}
                            onMouseEnter={() => setActive(idx)}
                        >
                            {val.name}
                        </a>
                    </Link>
                ))}
            </div>
            {mainData && mainData.show_right_sidebar && (
                <div className="right-sidebar" style={{ width: mainData.right_sidebar_width }}>
                    <CmsRenderer content={mainData.right_sidebar_html} />
                </div>
            )}
            <style jsx>
                {`
                    .row {
                        margin: 0;
                    }
                    .swift-nav-column-left {
                        background: ${mainData && mainData.dropdown_bgcolor ? mainData.dropdown_bgcolor : '#FFFFFF'};
                        padding: 16px;
                        border-radius: 8px !important;
                        width: 100%;
                        max-width: 1200px;
                        margin: 0 auto;
                        column-count: ${getCount(data.length)};
                        column-gap: 80px;
                        column-rule: 1px solid #dad9da;
                    }
                    .swift-nav-column-right {
                        padding: 16px 16px 16px 0;
                    }
                    .list-item__menu a {
                        font-weight: normal;
                        padding-bottom: 5px;
                        padding-top: 0px;
                    }
                `}
            </style>
        </div>
    );
};

export default MenuChildren;
